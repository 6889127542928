import React, { useState } from "react";
import TablePagination from "../Pagination/TablePagination";
import { DNSRecord } from "../../types/domain";

interface SubdomainTableProps {
  domainData: {
    domain_data: {
      dns_records: DNSRecord[];
    };
  };
}

const SubdomainTable: React.FC<SubdomainTableProps> = ({ domainData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  if (!domainData) {
    return (
      <div className="flex h-full items-center justify-center rounded-sm border border-stroke bg-white px-5 py-10 dark:border-strokedark dark:bg-boxdark">
        <p className="text-md font-medium text-black dark:text-white">
          Domain data not available
        </p>
      </div>
    );
  }

  const { dns_records } = domainData.domain_data;

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dns_records.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
      {/* Table Header */}
      <div className="px-4 py-6">
        <h4 className="text-xl font-semibold text-black dark:text-white">
          DNS Records
        </h4>
      </div>

      {/* Table Content */}
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                Type
              </th>
              <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white">
                Name
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((record: DNSRecord, index: number) => (
              <tr key={index}>
                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                  <span className="inline-flex rounded-full bg-meta-4 bg-opacity-90 py-1 px-3 text-sm font-medium text-white">
                    {record.type}
                  </span>
                </td>
                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                  <p className="text-black dark:text-white">
                    {record.domain}
                  </p>
                </td>
                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                  <p className="text-black dark:text-white">
                    {record.value}
                  </p>
                </td>
              </tr>
            ))}
            {currentItems.length === 0 && (
              <tr>
                <td
                  colSpan={3}
                  className="border-b border-[#eee] py-5 px-4 text-center dark:border-strokedark"
                >
                  <p className="text-black dark:text-white">
                    No DNS records found
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination - Now outside the overflow container */}
      <div className="mt-4">
        <TablePagination
          totalItems={dns_records.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          tableName="DNS records"
        />
      </div>
    </div>
  );
};

export default SubdomainTable;
