import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumbs/Breadcrumb";
import DefaultLayout from "../layout/DefaultLayout";
import HostsTable from "../components/Tables/HostsTable";
import OpenPortsDistributionChart from "../components/Charts/OpenPortsDistributionChart";
import ServicesDistributionChart from "../components/Charts/ServicesDistributionChart";
import { fetchDomainData } from "../api";
import { Loader } from "../components/ui/loader";

interface DomainData {
  hosts_data: HostData[];
}

interface HostData {
  ip: string;
  domains: string[];
  org: string;
  asn: string;
  ports: number[];
  last_seen: string;
  location: {
    city: string;
    country: string;
  };
  services: ServiceData[];
}

interface ServiceData {
  port: number;
  protocol: string;
  transport_protocol: string;
  components?: { component: string }[];
}

const Hosts: React.FC = () => {
  const [selectedPort, setSelectedPort] = useState<number | null>(null);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [domainData, setDomainData] = useState<DomainData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDomainData();
        setDomainData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePortClick = (port: number) => {
    setSelectedPort(port);
  };

  const handleServiceClick = (service: string) => {
    setSelectedService(service);
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Hosts" />
      {isLoading ? (
        <div className="flex h-[80vh] items-center justify-center">
          <Loader className="h-8 w-8 text-primary" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="col-span-1">
              {domainData ? (
                <OpenPortsDistributionChart
                  onPortClick={handlePortClick}
                  domainData={domainData}
                />
              ) : (
                <div>No data available for Open Ports Distribution</div>
              )}
            </div>
            <div className="col-span-1">
              {domainData ? (
                <ServicesDistributionChart
                  onServiceClick={handleServiceClick}
                  domainData={domainData}
                />
              ) : (
                <div>No data available for Services Distribution</div>
              )}
            </div>
          </div>
          <div className="mb-8">
            {domainData ? (
              <HostsTable
                selectedPort={selectedPort}
                selectedService={selectedService}
                domainData={domainData}
              />
            ) : (
              <div>No data available for Hosts Table</div>
            )}
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default Hosts;
