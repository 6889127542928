"use client"

import React, { useState, useEffect } from "react"
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import mockData from "../../mock/mock-data.json"

interface VulnerabilityData {
  name: string
  count: number
  color: string
}

const VulnerabilitiesChart: React.FC = () => {
  const [vulnerabilities, setVulnerabilities] = useState<VulnerabilityData[]>([])

  useEffect(() => {
    const vulnerabilityCounts = {
      Critical: { count: 0, color: "#FF3366" }, // Using a warning red color
      High: { count: 0, color: "#28354F" },     // Using midnight
      Medium: { count: 0, color: "#5D7295" },   // Using clarity
      Low: { count: 0, color: "#76EFE4" },      // Using digital
    }

    const currentDate = new Date()

    mockData.forEach((item) => {
      item.hosts_data.forEach((host) => {
        let vulnerabilityLevel: keyof typeof vulnerabilityCounts = "Low"

        // Check for critical ports
        if (host.ports.some((port) => [22, 3389, 5900].includes(port))) {
          vulnerabilityLevel = "Critical"
        }
        // Check for high severity ports
        else if (host.ports.some((port) => [21, 23, 445].includes(port))) {
          vulnerabilityLevel = "High"
        }
        // Check for medium severity ports
        else if (host.ports.some((port) => [80, 443, 8080].includes(port))) {
          vulnerabilityLevel = "Medium"
        }

        // Check for old last seen date
        const lastSeenDate = new Date(host.last_seen)
        const daysDifference = Math.floor((currentDate.getTime() - lastSeenDate.getTime()) / (1000 * 3600 * 24))
        if (daysDifference > 90) {
          vulnerabilityLevel = "High"
        }

        // Check for multiple associated domains
        if (host.domains.length > 5) {
          vulnerabilityLevel = "Medium"
        }

        vulnerabilityCounts[vulnerabilityLevel].count++
      })
    })

    setVulnerabilities(
      Object.entries(vulnerabilityCounts).map(([name, { count, color }]) => ({
        name,
        count,
        color,
      }))
    )
  }, [])

  return (
    <Card className="h-full w-full">
      <CardHeader>
        <CardTitle>Vulnerabilities Observed By Severity</CardTitle>
        <CardDescription>Overview of vulnerability levels across all hosts</CardDescription>
      </CardHeader>
      <CardContent className="flex-1">
        <div className="h-[360px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={vulnerabilities}>
              <XAxis
                dataKey="name"
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                stroke="#888888"
                fontSize={12}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value}`}
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="rounded-lg border bg-background p-2 shadow-sm">
                        <div className="grid grid-cols-2 gap-2">
                          <div className="flex flex-col">
                            <span className="text-[0.70rem] uppercase text-muted-foreground">
                              {payload[0].payload.name}
                            </span>
                            <span className="font-bold text-muted-foreground">
                              {payload[0].value}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return null
                }}
              />
              <Bar
                dataKey="count"
                radius={[4, 4, 0, 0]}
              >
                {vulnerabilities.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  )
}

export default VulnerabilitiesChart