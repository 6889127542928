import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Eye, Pencil, Trash2 } from "lucide-react";
import { fetchDomainData } from "../../api";
import { Loader } from "../../components/ui/loader";
import EditDomainModal from "../Modal/EditDomainModal";

interface DomainData {
  domain: string;
  subdomains: string[];
  tags: string[];
  isMainDomain?: boolean;
}

const DomainTable = () => {
  const [domainData, setDomainData] = useState<DomainData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchDomainData();
      setDomainData(data?.domain_data || null);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load domain data");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="rounded-2xl border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="flex items-center justify-center h-[400px]">
          <Loader className="w-8 h-8" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-2xl border border-stroke bg-white px-5 py-8 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
        <div className="flex items-center justify-center">
          <p className="text-meta-1">{error}</p>
        </div>
      </div>
    );
  }

  if (!domainData) {
    return (
      <div className="rounded-2xl border border-stroke bg-white px-5 py-8 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
        <div className="flex items-center justify-center">
          <p className="text-meta-2">No domain data available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="rounded-2xl border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                Domain
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                Subdomains
              </th>
              <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                Tags
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11 hover:text-primary">
                <Link to={`/domains/${domainData.domain}`}>
                  <h5 className="font-medium text-black dark:text-white">
                    {domainData.domain}
                  </h5>
                </Link>
              </td>
              <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                {domainData.subdomains?.map((subdomain, subdomainIndex) => (
                  <span
                    key={subdomainIndex}
                    className="inline-flex rounded bg-midnight py-1 px-2 text-sm font-medium text-white hover:bg-opacity-90 mr-2 mb-2"
                  >
                    {subdomain}
                  </span>
                ))}
              </td>
              <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                {domainData.tags?.map((tag, tagIndex) => (
                  <span
                    key={tagIndex}
                    className="inline-flex rounded bg-midnight py-1 px-2 text-sm font-medium text-white hover:bg-opacity-90 mr-2 mb-2"
                  >
                    {tag}
                  </span>
                ))}
              </td>
              <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                <div className="flex items-center space-x-3.5">
                  {!domainData.isMainDomain ? (
                    <>
                      <button
                        onClick={() => {
                          setSelectedDomain(domainData.domain);
                          setIsEditModalOpen(true);
                        }}
                        className="flex items-center justify-center w-8 h-8 rounded-full bg-clarity hover:bg-opacity-80 shadow-sm hover:shadow-md transition-shadow duration-300"
                      >
                        <Pencil className="w-4 h-4 text-white" />
                      </button>
                      <button
                        onClick={() => {
                          // Handle delete functionality
                          console.log('Delete domain:', domainData.domain);
                        }}
                        className="flex items-center justify-center w-8 h-8 rounded-full bg-strokedark hover:bg-opacity-80 shadow-sm hover:shadow-md transition-shadow duration-300"
                      >
                        <Trash2 className="w-4 h-4 text-white" />
                      </button>
                    </>
                  ) : (
                    <Link
                      to={`/domains/${domainData.domain}`}
                      className="flex items-center justify-center w-8 h-8 rounded-full bg-digital hover:bg-opacity-80 shadow-sm hover:shadow-md transition-shadow duration-300"
                    >
                      <Eye className="w-4 h-4 text-white" />
                    </Link>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isEditModalOpen && selectedDomain && (
        <EditDomainModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSave={(newValue) => {
            console.log('Save new value:', newValue);
            // Implement save functionality here
            setIsEditModalOpen(false);
          }}
          initialValue={selectedDomain}
        />
      )}
    </div>
  );
};

export default DomainTable;
