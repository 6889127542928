import { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import Loader from "./common/Loader";
import PageTitle from "./components/PageTitle";
import SignIn from "./pages/Authentication/SignIn";
import SignUp from "./pages/Authentication/SignUp";
import NotFound from "./pages/NotFound";
import TwoStepVerification from "./pages/Authentication/TwoStepVerification";
import Overview from "./pages/Dashboard/Overview";
import Domains from "./pages/Domains";
import Hosts from "./pages/Hosts";
import Settings from "./pages/Settings";
// import Reports from "./pages/Reports";
import DomainOverview from "./pages/DomainOverview";
import PrivateRoute from "./pages/Authentication/PrivateRoute";
import PaymentRequired from "./pages/Authentication/PaymentRequired";
import ExternalRedirect from "./components/ExternalRedirect";
function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />

        <Route
          path="/auth/signin"
          element={
            <>
              <PageTitle title="Digital Lighthouse" />
              <SignIn />
            </>
          }
        />
        <Route
          path="/auth/verify"
          element={
            <>
              <PageTitle title="Verify Email - Digital Lighthouse" />
              <TwoStepVerification />
            </>
          }
        />
        <Route
          path="/auth/signup"
          element={
            import.meta.env.VITE_ENV === "production" ? (
              // Use a component that performs window.location redirect for external URLs
              <ExternalRedirect url="https://securityoverview.com/digital-lighthouse-payment" />
            ) : (
              <Navigate to="/auth/signup/shield-monthly" replace />
            )
          }
        />
        <Route
          path="/auth/signup/:package"
          element={
            <>
              <PageTitle title="Digital Lighthouse" />
              <SignUp />
            </>
          }
        />
        <Route
          path="/payment-required"
          element={
            <>
              <PageTitle title="Digital Lighthouse" />
              <PaymentRequired />
            </>
          }
        />
        <Route
          path="/auth/two-step-verification"
          element={
            <>
              <PageTitle title="Digital Lighthouse" />
              <TwoStepVerification />
            </>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              element={
                <>
                  <PageTitle title="Digital Lighthouse" />
                  <Overview />
                </>
              }
            />
          }
        />
        <Route
          path="/domains"
          element={
            <PrivateRoute
              element={
                <>
                  <PageTitle title="Domains | Digital Lighthouse" />
                  <Domains />
                </>
              }
            />
          }
        />
        <Route
          path="/domains/:domain"
          element={
            <PrivateRoute
              element={
                <>
                  <PageTitle title="Domain Overview | Digital Lighthouse" />
                  <DomainOverview />
                </>
              }
            />
          }
        />
        <Route
          path="/domains/:domain/:subpath/*"
          element={
            <PrivateRoute
              element={
                <>
                  <PageTitle title="Domain Overview | Digital Lighthouse" />
                  <DomainOverview />
                </>
              }
            />
          }
        />
        <Route
          path="/hosts"
          element={
            <PrivateRoute
              element={
                <>
                  <PageTitle title="Hosts | Digital Lighthouse" />
                  <Hosts />
                </>
              }
            />
          }
        />
        {/* <Route
          path="/reports"
          element={
            <PrivateRoute element={
              <>
                <PageTitle title="Reports | Digital Lighthouse" />
                <Reports />
              </>
            } />
          }
        /> */}
        <Route
          path="/settings"
          element={
            <PrivateRoute
              element={
                <>
                  <PageTitle title="Settings | Digital Lighthouse" />
                  <Settings />
                </>
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <>
              <PageTitle title="404 Not Found | Digital Lighthouse" />
              <NotFound />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
