import React from 'react';

interface TablePaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  tableName?: string; // Optional name to identify which table is using pagination
}

const TablePagination: React.FC<TablePaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  tableName = 'table',
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the range of page numbers to show
  const getPageNumbers = () => {
    const delta = 2; // Number of pages to show before and after current page
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - delta && i <= currentPage + delta)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  return (
    <div className="flex flex-wrap items-center justify-between px-4 py-4 border-t border-stroke dark:border-strokedark">
      <div className="text-sm text-gray-500 dark:text-gray-400">
        Showing {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} to{' '}
        {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} {tableName} entries
      </div>
      
      <div className="flex items-center gap-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="flex items-center justify-center rounded-lg border border-stroke bg-white py-2 px-4 text-sm text-black hover:bg-gray-100 disabled:opacity-50 dark:border-strokedark dark:bg-boxdark dark:text-white dark:hover:bg-meta-4"
        >
          Previous
        </button>
        
        {getPageNumbers().map((number, index) => (
          <button
            key={index}
            onClick={() => typeof number === 'number' ? onPageChange(number) : null}
            disabled={typeof number !== 'number'}
            className={`flex items-center justify-center rounded-lg py-2 px-4 text-sm ${
              currentPage === number
                ? 'bg-primary text-white dark:text-black hover:bg-primary dark:bg-primary dark:hover:bg-primary'
                : typeof number === 'number'
                ? 'border border-stroke bg-white text-black hover:bg-gray-100 dark:border-strokedark dark:bg-boxdark dark:text-white dark:hover:bg-meta-4'
                : 'border border-stroke bg-white text-black dark:border-strokedark dark:bg-boxdark dark:text-white cursor-default'
            }`}
          >
            {number}
          </button>
        ))}
        
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="flex items-center justify-center rounded-lg border border-stroke bg-white py-2 px-4 text-sm text-black hover:bg-gray-100 disabled:opacity-50 dark:border-strokedark dark:bg-boxdark dark:text-white dark:hover:bg-meta-4"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TablePagination; 