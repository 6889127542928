import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LogoDark from "../../images/logo.png";
import BackgroundImage from "../../images/bg.png";
import { Loader } from "../../components/ui/loader";

const SignIn: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const response = await fetch(
        import.meta.env.VITE_ENV === "production"
          ? import.meta.env.VITE_API_BASE_URL + "/signin"
          : import.meta.env.VITE_DEV_API_BASE_URL + "/signin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email.toLowerCase(),
            password: password,
          }),
        }
      );

      const data = await response.json();

      // Handle unverified email case
      if (data.needsConfirmation) {
        navigate("/auth/verify", {
          state: {
            email: email,
            resend: true // Flag to show resend option
          }
        });
        return;
      }

      // Handle payment required case
      if (response.status === 402) {
        navigate("/payment-required", {
          state: {
            email: email,
            packageName: data.packageName,
          },
        });
        return;
      }

      if (!response.ok) {
        throw new Error(data.error || "Failed to sign in");
      }

      // Store tokens and user details
      if (data.accessToken) {
        localStorage.setItem("accessToken", data.accessToken);
        // Set session expiration if not keeping logged in
        if (!keepLoggedIn) {
          const expirationTime = new Date();
          expirationTime.setHours(expirationTime.getHours() + 24); // 24 hour expiration
          localStorage.setItem("sessionExpiration", expirationTime.toISOString());
        }
      }
      if (data.refreshToken) {
        localStorage.setItem("refreshToken", data.refreshToken);
      }
      if (data.userDetails) {
        localStorage.setItem("userDetails", JSON.stringify(data.userDetails));
      }

      // Redirect based on subscription status
      if (data.userDetails?.subscriptionStatus !== "active") {
        navigate("/payment-required", {
          state: {
            email: email,
            packageName: data.userDetails?.packageName,
          },
        });
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      setError(error instanceof Error ? error.message : "An error occurred during sign in");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    handleSignIn(e);
  };

  return (
    <div
      className="flex flex-col h-screen bg-cover bg-center md:flex-row"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {/* Logo for mobile */}
      <div className="flex justify-center items-center p-4 md:hidden">
        <img src={LogoDark} alt="Logo" className="w-32" />
      </div>

      {/* Left side with logo for larger screens */}
      <div className="hidden md:flex md:flex-1 md:justify-center md:items-center">
        <img src={LogoDark} alt="Logo" className="w-90 mb-8" />
      </div>

      {/* Right side with form */}
      <div className="flex-1 flex flex-col justify-center items-center bg-midnight p-8 md:p-12 md:h-[98vh] md:mt-[1vh]">
        <h2 className="text-3xl md:text-4xl text-white mb-4 md:mb-6">
          Welcome Back!
        </h2>
        <p className="text-sm md:text-base text-white mb-6 md:mb-8">
          Don't have an account yet?{" "}
          <Link to="/auth/signup" className="text-white">
            Sign Up
          </Link>
        </p>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <form onSubmit={handleSubmit} className="w-full max-w-xs md:max-w-sm">
          <div className="mb-4">
            <label className="block text-white mb-2">Username</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 rounded bg-white text-gray-800"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 rounded bg-white text-gray-800"
              required
            />
          </div>

          <div className="mb-6 flex justify-between items-center">
            <label className="flex items-center text-white">
              <input 
                type="checkbox" 
                className="mr-2" 
                checked={keepLoggedIn}
                onChange={(e) => setKeepLoggedIn(e.target.checked)}
              /> Keep me logged in
            </label>
            <Link to="/forgot-password" className="text-white">
              Forgot Password?
            </Link>
          </div>

          <button
            type="submit"
            className="w-full py-3 rounded bg-digital text-midnight hover:bg-steel hover:text-white transition font-medium flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader className="w-6 h-6" />
            ) : (
              "Sign In"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
