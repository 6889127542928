import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { 
  LayoutDashboard, 
  Globe, 
  Database, 
  Settings,
  ChevronLeft
} from "lucide-react";
import Logo from "../../images/logo.png";

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef<HTMLButtonElement>(null);
  const sidebar = useRef<HTMLElement>(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // Handle click outside
  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(event.target as Node) ||
        trigger.current.contains(event.target as Node)
      )
        return;
      setSidebarOpen(false);
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // Handle Escape key
  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (!sidebarOpen || event.key !== "Escape") return;
      setSidebarOpen(false);
    };

    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // Handle sidebar expansion
  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const navItems = [
    { path: "/dashboard", icon: LayoutDashboard, label: "Overview" },
    { path: "/domains", icon: Globe, label: "Domains" },
    { path: "/hosts", icon: Database, label: "Hosts" },
    { path: "/settings", icon: Settings, label: "Settings" },
  ];

  return (
    <aside
      ref={sidebar}
      className={`absolute left-0 top-0 z-50 flex h-screen flex-col overflow-y-hidden bg-boxdark duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      } ${sidebarExpanded ? "w-72" : "w-24"}`}
    >
      {/* Sidebar Header */}
      <div className="relative flex h-24 items-center justify-center bg-boxdark px-6">
        <NavLink 
          to="/dashboard" 
          className={`hidden md:flex items-center justify-center ${sidebarExpanded ? "w-full" : "w-16"}`}
        >
          <img 
            src={Logo} 
            className={`h-auto transform transition-all duration-300 ${
              sidebarExpanded ? "max-h-16 max-w-[160px]" : "max-h-12 min-w-[48px]"
            }`}
            alt="Logo" 
          />
        </NavLink>

        <button
          ref={trigger}
          onClick={() => {
            setSidebarOpen(!sidebarOpen);
            setSidebarExpanded(!sidebarExpanded);
          }}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="block text-white lg:hidden"
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
      </div>

      <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
        <nav className="mt-5 px-4 py-4 lg:mt-9 lg:px-6">
          <div>
            <h3 className={`mb-4 ml-4 text-sm font-medium text-white ${
              sidebarExpanded ? "block" : "hidden"
            }`}>
              MENU
            </h3>

            <ul className="mb-6 flex flex-col gap-1.5">
              {navItems.map((item) => (
                <li key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `group flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-white duration-300 ease-in-out hover:bg-graydark ${
                        isActive && "bg-graydark"
                      }`
                    }
                  >
                    <item.icon className="h-5 w-5" />
                    <span className={`whitespace-nowrap ${
                      sidebarExpanded ? "block" : "hidden"
                    }`}>
                      {item.label}
                    </span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>

      {/* Collapse Button */}
      <div className="mt-auto px-6 py-4 lg:block hidden">
        <button
          onClick={() => {
            setSidebarExpanded(!sidebarExpanded);
            setSidebarOpen(!sidebarOpen);
          }}
          className="flex w-full items-center justify-center gap-2 rounded-sm bg-graydark px-4 py-2 text-white hover:bg-opacity-90"
        >
          <ChevronLeft className={`h-5 w-5 transform transition-transform duration-200 ${
            !sidebarExpanded ? 'rotate-180' : ''
          }`} />
          <span className={sidebarExpanded ? "block" : "hidden"}>
            Collapse
          </span>
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;